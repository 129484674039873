<template>
  <div>
    <el-dialog
      title="Select Schedules"
      :visible.sync="displayStatus"
      width="50%"
      center
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="Submit Data For" label-width="250px;">
          <el-select v-model="selectedScheduleTime" filterable>
            <el-option
              v-for="(schedule, index) in schedules"
              :key="index"
              :value="schedule.value"
              :label="schedule.label"
              :disabled="schedule.completed"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addIntervalData(false)">Cancel</el-button>
        <el-button type="primary" @click="addIntervalData(true)"
          >Submit Data</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["workflowDetails", "completedIntervals", "showCompletedSchedules"],
  data() {
    return {
      selectedScheduleTime: "",
      schedules: [],
      displayStatus: true,
      valuesMap: {},
    };
  },
  mounted() {
    this.prepareSchedules();
  },
  methods: {
    prepareSchedules() {
      let intervalSettings = this.workflowDetails.settings.interval_settings;

      let fequencyMap = {
        DAILY: "days",
        WEEKLY: "weeks",
        MONTHLY: "months",
        YEARLY: "years",
        HOURLY: "hours",
      };

      let frequecyValue = 1;

      this.valuesMap = {};

      if (intervalSettings.type == "HOURLY") {
        let startDate = moment(intervalSettings.start_time)
          .utc()
          .startOf("hour");
        let currentDate = moment().utc().startOf("hour");
        let endDate = moment(intervalSettings.end_time).utc().startOf("hour");
        let lastDate = currentDate.isBefore(endDate) ? currentDate : endDate;

        this.valuesMap = {};
        for (
          let date = startDate;
          date.isBefore(lastDate);
          date = date.add(frequecyValue, fequencyMap[intervalSettings.type])
        ) {
          this.schedules.push({
            label: date.utc().format("YYYY-DD-MM HH:mm"),
            value: date.utc().format(),
          });

          this.valuesMap[date.utc().toISOString()] = this.schedules.length;
        }

        if (this.completedIntervals && this.completedIntervals.length) {
          this.completedIntervals.forEach((e) => {
            if (this.valuesMap[e]) {
              this.schedules[this.valuesMap[e] - 1].completed = true;
            }
          });
        }
      } else {
        let startDate = moment(intervalSettings.start_time)
          .utc()
          .startOf("day");
        let currentDate = moment().utc().startOf("day");
        let endDate = moment(intervalSettings.end_time).utc().startOf("day");
        let lastDate = currentDate.isBefore(endDate) ? currentDate : endDate;

        for (
          let date = startDate;
          date.isBefore(lastDate);
          date = date.add(frequecyValue, fequencyMap[intervalSettings.type])
        ) {
          this.schedules.push({
            label: date.utc().format("YYYY-DD-MM"),
            value: date.utc().format(),
          });

          this.valuesMap[date.utc().toISOString()] = this.schedules.length;
        }

        if (this.completedIntervals && this.completedIntervals.length) {
          this.completedIntervals.forEach((e) => {
            if (this.valuesMap[e]) {
              this.schedules[this.valuesMap[e] - 1].completed = true;
            }
          });
        }
      }
    },
    addIntervalData(status) {
      if (status) {
        this.$emit("AddSchedule", this.selectedScheduleTime);
      } else {
        this.$emit("showData");
      }
    },
  },
};
</script>

<style>
</style>